import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function MediaCard(props) {

  // var image_url = `http://localhost:8080/api/image?s3_key=${props.img}`
  // var image_url = `https://plum-unusual-seagull.cyclic.app/api/image?s3_key=${props.img}`
  var image_url = `https://api.gusagusa.com/api/image?s3_key=${props.img}`
  console.log(props)
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Item sx={{ height: "100%" }}>
        <Card sx={{ maxWidth: 600, height: '100%', display: "flex", flexDirection: "column" }}>
          <CardMedia
            sx={{ height: 200, position: 'relative', backgroundSize: 'cover', backgroundPosition: 'center 15%' }}
            image={image_url}
            title={props.title}
          >
            <div style={{
              position: 'absolute',
              bottom: '15px',
              right: '5px',
              color: 'black',
              backgroundColor: 'rgba(255, 255, 255, 0.3)'
            }}>
              <Typography variant="body" component="body" key="title">{props.time_ago}</Typography>
            </div>
          </CardMedia>
          <CardContent sx={
            {
              textAlign: 'left',
              marginBottom: '1',
              paddingBottom: '0px',
              "&:last-child": {
                paddingBottom: "5px"
              }
            }
          }>
            <Typography gutterBottom variant="h6" component="h2" key="title">
              {props.title}
            </Typography>
            <Typography variant="body" color="text.primary" key="desc" sx={{ marginBottom: "1rem" }}>
              {props.description}
            </Typography>
          </CardContent>
        </Card>
      </Item>
    </Grid>
  );
}