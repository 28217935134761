import React from "react";
import { Container } from "@mui/material";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import MediaCard from "../Components/MediaCard";
import { useEffect, useState } from 'react';
import LinearDeterminate from "../Components/LinearDeterminate";


//http://localhost:8080/api/articles

export default function Home() {

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  // var url = "https://plum-unusual-seagull.cyclic.app/api/articles"
  // var url = "http://localhost:8080/api/articles"
  var url = "https://api.gusagusa.com/api/articles"

  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setList(data)
        setLoading(false)
        // console.log(data)
      });
  }, []);


  return (
    <>
      {console.log("list", list)}
      <Container>
        <ResponsiveAppBar />
        {loading && <LinearDeterminate />}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {list.map((item) => (
              <MediaCard img={item.s3_key} title={item.summary_title} description={item.summary} time_ago={item.time_ago} />
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
}
